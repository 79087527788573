var DK = DK || {};
DK.finder = {
  loading: false,
  showSavedRides: function () {
    $('#finder-saved-rides tr.hidden').removeClass('hidden');
  },
  switchTab: function (link) {
    let tabSelector = '#' + $(link).attr('id'),
      thisModal = $(link).parents('#finder-wrapper');
    let contentSelector = tabSelector + '-content';
    $('.f__tabs a').removeClass('active');
    $(link).addClass('active');
    $('.finder .f__tab__content').addClass('hidden');
    thisModal.find(contentSelector).removeClass('hidden');
    bootstrap.Modal.getInstance($(link).parents('.modal')[0]).handleUpdate();
    $('.rf--modal [data-morty]').morty({
      modal: true
    });
  },
  tireFinderModelChange: function (obj, ps, make, model, year) {
    let params = {
      makeId: make,
      modelId: model,
      year: year,
      type: 'mmy'
    };
    let category = $('div.finder').attr('data-category');
    let newContentURIBase = '/finder/tire/' + category;
    $.getJSON('/ride-finder.json', params, function (data) {
      let newContentURI = newContentURIBase + data[0].seoUri;
      DK.finder.reload(newContentURI);
    });
  },
  reload: function (url) {
    if (DK.finder.loading) {
      return false;
    }
    let $finderContent = $('div#finder-wrapper');
    let $parent = $finderContent.parent();
    DK.finder.loading = true;
    $.get(url, function (html) {
      let serverContent = $.parseHTML(html, true);
      $finderContent.remove();
      $parent.append(serverContent[1]);
      DK.finder.init();
      $('.finder [data-tire-size-selector]').sizeselector();
      $('.rf--modal [data-morty]').morty({
        modal: true
      });
      DK.finder.loading = false;
    });
  },
  init: function () {
    $('#view-all-rides').on('click', function () {
      DK.finder.showSavedRides();
      $(this).addClass('hidden');
      return false;
    });
    $('.f__tabs a').on('click', function () {
      DK.finder.switchTab(this);
      return false;
    });
    $('a.js-reloader, a.f__footer__l').on('click', function () {
      DK.finder.reload($(this).data('url'));
      return false;
    });
  }
};

//Trigger mmy selector for finder pages (exhaust/tire)
$(function () {
  $('.finder [data-tire-size-selector]').sizeselector();
  $('.finder [data-handlebar-size-selector]').handlebarSelector();
  $('.f__tabs a').off();
  DK.finder.init();
});